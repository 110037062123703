import {Footer} from './Footer'

const FooterWrapper = () => {
  return (
    <div className='app-footer app-footer-fixed' id='kt_app_footer'>
      <div className='app-container d-flex flex-column flex-md-row flex-center flex-md-stack py-3'>
        <Footer />
      </div>
    </div>
  )
}

export {FooterWrapper}
