import {Checkbox, Col, Divider, Form, Input, Row, Typography} from 'antd'
import {addDoc, doc, getDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {firestore, fireStoreRef} from '../../../firebase'
import {RoutesList} from '../../routing/RoutesList'
import AddUserViaSearch from './AddUserViaSearch'
import {sendEmail} from '../../sendMail'
import {DataType} from '../../../interface'

const initialState: DataType = {
  name: '',
  email: '',
  mobileNumber: '',
  age: '',
  address: '',
  date: '',
  leftSphere: 0,
  leftCycle: 0,
  leftAxis: 0,
  leftAddition: '',
  rightSphere: 0,
  rightCycle: 0,
  rightAxis: 0,
  rightAddition: '',
  bifocal: false,
  progressive: false,
  single: false,
  rightPd: '',
  leftPd: '',
  bothPd: '',
}

const AddUser = () => {
  const [fields, setFields] = useState(initialState)
  const [selectedValue, setSelectedValue] = useState('')
  const navigate = useNavigate()

  const handleChange = (e: any) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setFields({...fields, [e.target.name]: value})
  }

  const createNewDocument = async () => {
    try {
      if (!fields.name || !fields.age || !fields.address || !fields.mobileNumber || !fields.date) {
        toast.error('Please fill required fields')
        return
      }

      if (fields.mobileNumber.length !== 10) {
        toast.error('Mobile number should have 10 digits')
        return
      }
      const docref = await addDoc(fireStoreRef, fields)
      toast.success('Customer added successfully')

      if (fields.email) {
        await sendEmail(
          fields.email,
          fields.name,
          `${window.location.protocol}//${window.location.hostname}/report/${docref?.id}`
        )
      }
      setFields(initialState)
      navigate(RoutesList.Dashboard)
    } catch (error) {
      toast.error('Unable to add, Please try later')
    }
  }

  const setFieldsForExistingCustomer = async () => {
    if (selectedValue) {
      const docRef = doc(firestore, 'userDetails', selectedValue as string)
      const res = await getDoc(docRef)
      if (res.exists()) {
        setFields({
          ...fields,
          name: res.data().name,
          email: res.data().email,
          mobileNumber: res.data().mobileNumber,
          age: res.data().age,
          address: res.data().address,
        })
      }
    }
  }

  useEffect(() => {
    setFieldsForExistingCustomer()
  }, [selectedValue])

  return (
    <>
      <AddUserViaSearch setSelectedNumber={setSelectedValue} />
      <Form layout='vertical' onSubmitCapture={createNewDocument}>
        <Divider orientation='left'>Basic Details</Divider>
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col span={12}>
            <Form.Item label='Name' required>
              <Input
                placeholder='Please enter name'
                name='name'
                type='text'
                onChange={handleChange}
                value={fields.name}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Email'>
              <Input
                placeholder='Please enter email'
                name='email'
                type='email'
                onChange={handleChange}
                value={fields.email}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item required label='Mobile Number'>
              <Input
                type='text'
                name='mobileNumber'
                placeholder='Please enter mobile number'
                onChange={handleChange}
                value={fields.mobileNumber}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Age' required>
              <Input
                type='number'
                placeholder='Please enter age'
                onChange={handleChange}
                value={fields.age}
                name='age'
                min={1}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Address' required>
              <Input
                type='text'
                placeholder='Please enter address'
                onChange={handleChange}
                value={fields.address}
                name='address'
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Date' required>
              <Input type='date' onChange={handleChange} value={fields.date} name='date' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-5 align-items-center'>
          <Col span={4}>
            <Typography>Right Eye</Typography>
          </Col>
          <Col span={5}>
            <Form.Item label='SPH'>
              <Input
                type='number'
                step='0.25'
                name='rightSphere'
                onChange={handleChange}
                value={fields.rightSphere}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='CYL'>
              <Input
                type='number'
                step='0.25'
                name='rightCycle'
                onChange={handleChange}
                value={fields.rightCycle}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='AXIS (in degrees)'>
              <Input
                type='number'
                step='1'
                name='rightAxis'
                onChange={handleChange}
                value={fields.rightAxis}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Addition'>
              <Input
                type='text'
                name='rightAddition'
                onChange={handleChange}
                value={fields.rightAddition}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-5 align-items-center'>
          <Col span={4}>
            <Typography>Left Eye</Typography>
          </Col>
          <Col span={5}>
            <Form.Item label='SPH'>
              <Input
                type='number'
                step='0.25'
                name='leftSphere'
                onChange={handleChange}
                value={fields.leftSphere}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='CYL'>
              <Input
                type='number'
                step='0.25'
                name='leftCycle'
                onChange={handleChange}
                value={fields.leftCycle}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='AXIS (in degrees)'>
              <Input
                type='number'
                step='1'
                name='leftAxis'
                onChange={handleChange}
                value={fields.leftAxis}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Addition'>
              <Input
                type='text'
                name='leftAddition'
                onChange={handleChange}
                value={fields.leftAddition}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation='left'>Additional Info</Divider>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-5 align-items-center'>
          <Col span={6}>
            <Typography>PD</Typography>
          </Col>
          <Col span={6}>
            <Form.Item label='Right Eye'>
              <Input type='number' name='rightPd' onChange={handleChange} value={fields.rightPd} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Left Eye'>
              <Input type='number' name='leftPd' onChange={handleChange} value={fields.leftPd} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Both Eyes'>
              <Input type='number' name='bothPd' onChange={handleChange} value={fields.bothPd} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-5'>
          <Col span={8}>
            <Checkbox name='bifocal' onChange={handleChange} checked={fields.bifocal}>
              Bifocal
            </Checkbox>
          </Col>
          <Col span={8} className='mt-3'>
            <Checkbox name='progressive' onChange={handleChange} checked={fields.progressive}>
              Progressive
            </Checkbox>
          </Col>
          <Col span={8} className='mt-3'>
            <Checkbox name='single' onChange={handleChange} checked={fields.single}>
              Single Vision
            </Checkbox>
          </Col>
        </Row>

        <div style={{marginBottom: '5rem'}}>
          <button className='btn btn-primary mt-10'>Add user</button>
          <button onClick={() => setFields(initialState)} className='ms-2 btn btn-danger mt-10'>
            Reset
          </button>
        </div>
      </Form>
    </>
  )
}

export default AddUser
