import {useAuth} from '../../../../zustand/auth.store'

const Navbar = () => {
  const {userProfile} = useAuth()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className='app-navbar-item ms-1 ms-lg-3'>
        <div
          className=' symbol symbol-35px symbol-md-40px'
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {userProfile.email}
        </div>
        {/* <HeaderUserMenu /> */}
      </div>
    </div>
  )
}

export {Navbar}
