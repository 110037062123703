import {PDFViewer} from '@react-pdf/renderer'
import {Spin} from 'antd'
import {FC, useEffect, useState} from 'react'
import PDFReport from '../../components/PDFReport'
import {useParams} from 'react-router-dom'
import {DataType} from '../../../interface'
import {firestore} from '../../../firebase'
import {doc, getDoc} from 'firebase/firestore'

interface PrintReportProps {}

const PrintReport: FC<PrintReportProps> = ({}) => {
  const {id} = useParams()
  const [data, setData] = useState<DataType>({} as DataType)
  const [isFetchingDoc, setIsFetchingDoc] = useState(false)

  const docRef = doc(firestore, 'userDetails', id as string)

  const fetchSingleCustomerData = async () => {
    setIsFetchingDoc(true)
    const document = await getDoc(docRef)

    if (document.exists()) {
      setData(document.data() as DataType)
    } else {
      setData({} as DataType)
    }
    setIsFetchingDoc(false)
  }

  useEffect(() => {
    fetchSingleCustomerData()
  }, [])

  return (
    <div>
      {isFetchingDoc ? (
        <Spin tip='Generating your report' size='large'></Spin>
      ) : (
        <PDFViewer showToolbar={true} height={'750px'} width={'750px'}>
          <PDFReport data={data} />
        </PDFViewer>
      )}
    </div>
  )
}

export default PrintReport
