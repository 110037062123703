import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {RoutesList} from './RoutesList'
import DashboardPage from '../pages/dashboard/DashboardWrapper'
import AddUser from '../pages/add-user'
import PrintReport from '../pages/report/PrintReport'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={RoutesList.Dashboard} />} />
        {/* Pages add routes here */}
        <Route path={RoutesList.Dashboard} element={<DashboardPage />} />
        <Route path={RoutesList.AddUser} element={<AddUser />} />
        <Route path={RoutesList.PrintReport} element={<PrintReport />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
