import {Table} from 'antd'
import type {ColumnsType} from 'antd/es/table'
import {FC, useState} from 'react'
import {EditFilled, EyeFilled, DeleteFilled, WhatsAppOutlined} from '@ant-design/icons'
import DataModal from './ViewDetailsModal/DataModal'
import EditUserModal from './EditUserModal/EditUserModal'
import {DataType} from '../../interface'
import {deleteDoc, doc} from 'firebase/firestore'
import {firestore} from '../../firebase'
import {toast} from 'react-toastify'

interface IDatatableComponentProps {
  tableData: DataType[]
  fetchAllDocs: () => void
}

const DatatableComponent: FC<IDatatableComponentProps> = ({tableData, fetchAllDocs}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [columnData, setColumnData] = useState<DataType>()

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {title: 'Phone Number', dataIndex: 'mobileNumber', key: 'mobileNumber'},
    {title: 'Address', dataIndex: 'address', key: 'address'},
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '',
      dataIndex: '',
      render: (singleCol: DataType) => (
        <div>
          <EditFilled
            style={{fontSize: '20px'}}
            title='Edit Details'
            onClick={() => {
              handleEditClicked(singleCol)
            }}
            className='me-4 cursor-pointer text-warning'
          />
          <EyeFilled
            title='View Details'
            onClick={() => handleViewClicked(singleCol)}
            className='me-4 cursor-pointer text-primary'
            style={{fontSize: '20px'}}
          />
          <DeleteFilled
            title='Delete record'
            onClick={() => handleDeleteClicked(singleCol?.id)}
            className='me-4 cursor-pointer text-danger'
            style={{fontSize: '20px'}}
          />
          <WhatsAppOutlined
            title='Share'
            onClick={() => handleShare(singleCol)}
            className='me-4 cursor-pointer text-success'
            style={{fontSize: '20px'}}
          />
        </div>
      ),
    },
  ]

  const handleModalClose = () => setShowModal(false)

  const handleEditModalClose = () => {
    setShowEditModal(false)
  }

  const handleViewClicked = (data: DataType) => {
    setShowModal(true)
    setColumnData(data)
  }

  const handleEditClicked = (data: DataType) => {
    setShowEditModal(true)
    setColumnData(data)
  }

  const handleDeleteClicked = async (id: string | undefined) => {
    if (id) {
      const docRef = doc(firestore, 'userDetails', id)
      deleteDoc(docRef)
        .then(() => {
          toast.success('User details deleted successfully')
          fetchAllDocs()
        })
        .catch((e) => {
          toast.error('Unable to delete user. Please try later')
        })
    }
  }

  const handleShare = async (data: DataType) => {
    if (data) {
      window
        .open(
          `https://wa.me/91${data?.mobileNumber}?text=Please download your test report by clicking on the below link. For any more queries please reach out to us. ${window.location.protocol}//${window.location.hostname}/report/${data?.id}`,
          '_blank'
        )
        ?.focus()
    }
  }

  return (
    <>
      <EditUserModal
        isOpen={showEditModal}
        handleClose={handleEditModalClose}
        fetchAllDocs={fetchAllDocs}
        data={columnData as DataType}
      />
      <DataModal isOpen={showModal} handleClose={handleModalClose} data={columnData as DataType} />
      <Table
        scroll={{x: true}}
        pagination={{
          pageSize: 10,
          current: currentPage,
          responsive: true,
          onChange: (p) => setCurrentPage(p),
        }}
        columns={columns}
        dataSource={tableData}
      />
    </>
  )
}

export default DatatableComponent
