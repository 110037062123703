import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  return (
    <div className='d-flex flex-column flex-center flex-lg-row flex-column-fluid h-100'>
      <div className='w-lg-500px p-10'>
        <div className='text-center'>
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logo/logo.png')} height={256} />
          </Link>
        </div>

        <Outlet />
      </div>
    </div>
  )
}

export {AuthLayout}
