import {PDFDownloadLink} from '@react-pdf/renderer'
import {Button, Spin} from 'antd'
import {doc, getDoc} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {firestore} from '../../../firebase'
import {DataType} from '../../../interface'
import PDFReport from '../../components/PDFReport'
import {DownloadOutlined} from '@ant-design/icons'

const Pdf = () => {
  const {id} = useParams()
  const [data, setData] = useState<DataType>({} as DataType)
  const [isFetchingDoc, setIsFetchingDoc] = useState(false)

  const docRef = doc(firestore, 'userDetails', id as string)

  const fetchSingleCustomerData = async () => {
    setIsFetchingDoc(true)
    const document = await getDoc(docRef)

    if (document.exists()) {
      setData(document.data() as DataType)
    } else {
      setData({} as DataType)
    }
    setIsFetchingDoc(false)
  }

  useEffect(() => {
    fetchSingleCustomerData()
  }, [])

  return (
    <div className='vh-100 d-flex align-items-center justify-content-center'>
      <PDFDownloadLink
        document={<PDFReport data={data} />}
        fileName={`Test_report_${data?.name}.pdf`}
      >
        {({loading}) =>
          loading || isFetchingDoc ? (
            <Spin tip='Generating your report' size='large'></Spin>
          ) : (
            <>
              <Button icon={<DownloadOutlined />} type='primary'>
                Download your Report
              </Button>
            </>
          )
        }
      </PDFDownloadLink>
    </div>
  )
}

export default Pdf
