import {Modal} from 'antd'
import {FC} from 'react'
import {DataType} from '../../../interface'
import EdiUser from './EdiUser'

interface IEditUserModalProps {
  isOpen: boolean
  handleClose: () => void
  fetchAllDocs: () => void
  data: DataType
}

const EditUserModal: FC<IEditUserModalProps> = ({isOpen, data, handleClose, fetchAllDocs}) => {
  return (
    <Modal
      footer={[]}
      width={'50%'}
      bodyStyle={{height: '50rem', overflowY: 'scroll', overflowX: 'hidden'}}
      centered
      destroyOnClose
      closable={false}
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <EdiUser data={data} handleClose={handleClose} fetchAllDocs={fetchAllDocs} />
    </Modal>
  )
}

export default EditUserModal
