import {Checkbox, Col, Divider, Form, Input, Row, Typography} from 'antd'
import {FC} from 'react'
import {DataType} from '../../../interface'

interface IDataViewProps {
  data: DataType
}

const DataView: FC<IDataViewProps> = ({data}) => {
  return (
    <div>
      <Form layout='vertical'>
        <Divider orientation='left'>Basic Details</Divider>
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col span={12}>
            <Form.Item label='Name' required>
              <Input placeholder='Please enter name' name='name' type='text' value={data?.name} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Email'>
              <Input
                placeholder='Please enter email'
                name='email'
                type='email'
                value={data?.email}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item required label='Mobile Number'>
              <Input
                type='text'
                name='mobileNumber'
                placeholder='Please enter mobile number'
                value={data?.mobileNumber}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Age' required>
              <Input
                type='number'
                placeholder='Please enter age'
                value={data?.age}
                name='age'
                min={1}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Address' required>
              <Input
                type='text'
                placeholder='Please enter address'
                value={data?.address}
                name='address'
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Date' required>
              <Input type='date' value={data?.date} name='date' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-3 align-items-center'>
          <Col span={4}>
            <Typography>Right Eye</Typography>
          </Col>
          <Col span={5}>
            <Form.Item label='SPH'>
              <Input type='number' step='0.25' name='rightSphere' value={data?.rightSphere} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='CYL'>
              <Input type='number' step='0.25' name='rightCycle' value={data?.rightCycle} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='AXIS (in degrees)'>
              <Input type='number' step='1' name='rightAxis' value={data?.rightAxis} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Addition'>
              <Input type='text' name='rightAddition' value={data?.rightAddition} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-1 align-items-center'>
          <Col span={4}>
            <Typography>Left Eye</Typography>
          </Col>
          <Col span={5}>
            <Form.Item label='SPH'>
              <Input type='number' step='0.25' name='leftSphere' value={data?.leftSphere} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='CYL'>
              <Input type='number' step='0.25' name='leftCycle' value={data?.leftCycle} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='AXIS (in degrees)'>
              <Input type='number' step='1' name='leftAxis' value={data?.leftAxis} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='Addition'>
              <Input type='text' name='leftAddition' value={data?.leftAddition} />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation='left'>Additional Info</Divider>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-3 align-items-center'>
          <Col span={6}>
            <Typography>PD</Typography>
          </Col>
          <Col span={6}>
            <Form.Item label='Right Eye'>
              <Input type='number' name='rightPd' value={data?.rightPd} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Left Eye'>
              <Input type='number' name='leftPd' value={data?.leftPd} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='Both Eyes'>
              <Input type='number' name='bothPd' value={data?.bothPd} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className='mt-1'>
          <Col span={8}>
            <Checkbox name='bifocal' checked={data?.bifocal}>
              Bifocal
            </Checkbox>
          </Col>
          <Col span={8} className='mt-3'>
            <Checkbox name='progressive' checked={data?.progressive}>
              Progressive
            </Checkbox>
          </Col>
          <Col span={8} className='mt-3'>
            <Checkbox name='single' checked={data?.single}>
              Single Vision
            </Checkbox>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default DataView
