import {Select} from 'antd'
import {getDocs} from 'firebase/firestore'
import {FC, useEffect, useState} from 'react'
import {fireStoreRef} from '../../../firebase'

interface IAddUserViaSearch {
  setSelectedNumber: (t: any) => void
}

const AddUserViaSearch: FC<IAddUserViaSearch> = ({setSelectedNumber}) => {
  const [optionsArray, setOptionsArray] = useState<Array<any>>([])

  const fetchAllDocs = async () => {
    const res = await getDocs(fireStoreRef)
    res.docs.forEach((i) => {
      setOptionsArray((p) => [
        ...p,
        {label: `${i.data().mobileNumber} - ${i.data().name}`, value: i.id},
      ])
    })
  }

  useEffect(() => {
    fetchAllDocs()
  }, [])

  return (
    <Select
      showSearch
      size={'middle'}
      placeholder='Please type in mobile number to search for existing customers'
      onChange={(i) => setSelectedNumber(i)}
      style={{width: '30rem'}}
      options={optionsArray}
    />
  )
}

export default AddUserViaSearch
