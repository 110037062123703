import {getDocs} from 'firebase/firestore'
import {FC, useEffect, useState} from 'react'
import {fireStoreRef} from '../../../firebase'
import {DataType} from '../../../interface'
import DatatableComponent from '../../components/DatatableComponent'
import Moment from 'moment'

const DashboardPage: FC = () => {
  const [tableData, setTableData] = useState<Array<DataType>>([])
  const [filteredData, setFilteredData] = useState<Array<DataType>>([])
  const [searchText, setSearchText] = useState('')

  const fetchAllDocs = async () => {
    const unSortedArray: Array<any> = []
    let sortedArr: Array<any> = []
    setTableData([])
    setFilteredData([])
    const res = await getDocs(fireStoreRef)

    res.docs.forEach((i) => {
      unSortedArray.push({...i.data(), id: i.id})
      const sorted = unSortedArray.sort(
        //@ts-ignore
        (a, b) => new Moment(b.date).format('YYYYMMDD') - new Moment(a.date).format('YYYYMMDD')
      )
      sortedArr = sorted
    })
    setFilteredData(sortedArr)
    setTableData(sortedArr)
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    const t = tableData.filter((i) => i.mobileNumber.toString().includes(e.target.value))
    setFilteredData(t)
  }

  useEffect(() => {
    fetchAllDocs()
  }, [])

  return (
    <div className='p-8'>
      <div className='mb-10'>
        <input
          value={searchText}
          onChange={handleSearch}
          type='text'
          className='form-control'
          placeholder='Enter a phone number to search records'
        />
      </div>
      <DatatableComponent tableData={filteredData} fetchAllDocs={fetchAllDocs} />
    </div>
  )
}

export default DashboardPage
