import {FC} from 'react'
import {Button, Modal} from 'antd'
import {WhatsAppOutlined} from '@ant-design/icons'
import DataView from './DataView'
import {DataType} from '../../../interface'
import {sendEmail} from '../../sendMail'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'

interface IDataModalProps {
  isOpen: boolean
  handleClose: () => void
  data: DataType
}

const DataModal: FC<IDataModalProps> = ({isOpen, data, handleClose}) => {
  const navigation = useNavigate()

  const handleMail = async () => {
    const res = await sendEmail(
      data.email,
      data.name,
      `${window.location.protocol}//${window.location.hostname}/report/${data?.id}`
    )
    if (res.success) {
      toast.success(`Mail sent to ${data.email}`)
    } else {
      toast.error(res.error as string)
    }
  }

  const handlePrint = async () => {
    const path = `/print/report/${data?.id}`
    navigation(path)
  }

  return (
    <Modal
      width={'50%'}
      bodyStyle={{
        height: '50rem',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
      centered
      open={isOpen}
      destroyOnClose
      closable={false}
      footer={[
        <Button
          target='blank'
          href={`https://wa.me/91${data?.mobileNumber}?text=Please download your test report by clicking on the below link. For any more queries please reach out to us. ${window.location.protocol}//${window.location.hostname}/report/${data?.id}`}
          key='submit'
          icon={<WhatsAppOutlined />}
          type='primary'
        >
          Share on Whatsapp
        </Button>,
        <Button disabled={!data?.email} key='send-mail' onClick={handleMail}>
          Send Mail
        </Button>,
        <Button key='print' onClick={handlePrint}>
          Print
        </Button>,
        <Button key='back' onClick={handleClose}>
          Close
        </Button>,
      ]}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <DataView data={data} />
    </Modal>
  )
}

export default DataModal
