/* eslint-disable react/jsx-no-target-blank */
import {RoutesList} from '../../../../app/routing/RoutesList'
import {IUser, useAuth} from '../../../../zustand/auth.store'
import {SidebarMenuItem} from './sidebar-menu-helpers/SidebarMenuItem'
import {SidebarMenuItemWithSub} from './sidebar-menu-helpers/SidebarMenuItemWithSub'

const SidebarMenuItemsList = () => {
  const {setLoggedInStatus, setUserProfile} = useAuth()
  return (
    <>
      <SidebarMenuItem
        to={RoutesList.Dashboard}
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to={RoutesList.AddUser}
        icon='/media/icons/duotune/general/gen019.svg'
        title='Add User'
        fontIcon='bi-layers'
      />
      <div
        onClick={() => {
          setLoggedInStatus(false)
          setUserProfile({} as IUser)
        }}
      >
        <SidebarMenuItem
          to={''}
          icon='/media/icons/duotune/general/gen049.svg'
          title='Logout'
          fontIcon='bi-layers'
        />
      </div>
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export {SidebarMenuItemsList}
