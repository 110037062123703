import {Document, PDFViewer, Page, Text} from '@react-pdf/renderer'

import {FC} from 'react'
import {Html} from 'react-pdf-html'
import {DataType} from '../../interface'
import {toAbsoluteUrl} from '../../_metronic/helpers'

interface IPDFReportProps {
  data: DataType
}

const PDFReport: FC<IPDFReportProps> = ({data}) => {
  const html = `  
  <div style="width: 600px; padding-left:50px;padding-right:50px;background-color: rgb(241, 241, 241); height:100%">
    <table
      style="height: 250px;padding-bottom: 20px;width: 100%;display: table;table-layout: fixed;>
    <tr>
    <td style="display: table;height: 100%;width: 100%;">
    <span style="display: table-cell;text-align: center;">
    <img style="height: 80px; width:90px" src=${toAbsoluteUrl(
      '/media/logo/logo.png'
    )} alt="pampering eyes" />
      </span>
      </td>
      <td style="margin-top: 20px;margin-left: 380px;">
      <span style="font-size:12px;">
      ${data.date}
      </span>
      </td>
      </tr>
      </table>

    <div style="margin-top: 10px;">
      <div>
        <div style="background-color: rgb(29, 152, 152);border-top-left-radius: 5px;border-top-right-radius: 5px; width: 100%;padding-top: 10px;padding-bottom: 10px; padding-left: 20px;">
          <span style="color: #fff; text-transform: capitalize; font-size: 13px;"
            >Personal Details</span
          >
        </div>
        <div style="padding-top: 20px; padding-bottom: 20px; background-color: #fff; width: 100%; border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;">
          <table style="width: 100%; padding-left: 20px; padding-right: 20px;">
            <tr style="width: 100%; padding-left: 50px;">
              <td><span style="font-weight: 600; font-size: 10px;">Name</span></td>
              <td><span style="font-weight: lighter;font-size: 10px;">${data.name}</span></td>
            </tr>
          </table>
          <table style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <tr style="width: 100%; padding-left: 50px;">
              <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Age</span></td>
              <td><span style="font-weight: lighter;font-size: 10px; width: 50%;">${
                data.age
              }</span></td>
            </tr>
          </table>
          <table style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <tr style="width: 100%; padding-left: 50px;">
              <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Email</span></td>
              <td><span style="font-weight: lighter;font-size: 10px; width: 50%;">${
                data?.email || ''
              }</span></td>
            </tr>
          </table>
          <table style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <tr style="width: 100%; padding-left: 50px;">
            <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Phone Number</span></td>
            <td><span style="font-weight: lighter;font-size: 10px; width: 50%;">${
              data.mobileNumber
            }</span></td>
            </tr>
          </table>
          <table style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <tr style="width: 100%; padding-left: 50px;">
              <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Address</span></td>
              <td><span style="font-weight: lighter;font-size: 10px; width: 50%;">${
                data.address
              }</span></td>
            </tr>
          </table>
         
        </div>
      </div>
      <div style="margin-top: 20px;">
        <div style="background-color: rgb(29, 152, 152);border-top-left-radius: 5px;
        border-top-right-radius: 5px; width: 100%; font-size: 18px; padding-top: 10px;
        padding-bottom: 10px; margin-top: 10px; padding-left: 20px;">
          <span style="color: #fff; text-transform: capitalize; font-size: 13px; padding-left: 20px;">Test Results</span>
        </div>
        <table style="border-bottom-left-radius: 5px;border-bottom-right-radius: 5px; justify-content: space-evenly; padding-top: 20px; padding-bottom: 20px;padding-left:20px; background-color: #fff; width: 100%;">
          <tr>
          <td>
          <table>
            <tr>
              <span style="margin-bottom: 5px;">&nbsp;</span>
            </tr>
            <tr>
              <span
              style="padding-bottom: 5px; padding-top: 17px; display: flex; justify-content: start; align-items: center; margin-bottom: 5px; margin-right: 10px; font-size: 10px; font-weight: 600; color: rgb(29, 152, 152);">Right Eye</span>
            </tr>
            <tr>
              <span style="padding-bottom: 5px; padding-top: 8px; display: flex; justify-content: start; align-items: center; margin-bottom: 5px; margin-right: 10px; font-size: 10px; font-weight: 600; color: rgb(29, 152, 152);">
              Left Eye
              </span>
            </tr>
          </table>
          </td>
          <td>
          <div style="width: 50px;">
              <div style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;">SPH</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">
              ${data?.rightSphere}
              </div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">
              ${data?.leftSphere}
              </div>
          </div>
          </td>
          <td>
          <div style="width: 50px;">
              <div style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;">CYL</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">${
                data?.rightCycle
              }</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">
                ${data?.leftCycle}
              </div>
          </div>
          </td>
          <td>
          <div style="width: 50px;margin-right:10px;">
            <div
              style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;"
            >
              AXIS
            </div>
            <div
              style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;"
            >
            ${data?.rightAxis} 
            </div>
            <div
              style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;"
            >
            ${data?.leftAxis}
            </div>
          </div>
          </td>
          <td>
          <div style="width: 50px;">
            <div
              style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;"
            >
            Addition 
            </div>
            <div
              style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;"
            >
            ${data?.rightAddition}
            </div>
            <div
              style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;"
            >
            ${data?.leftAddition}
            </div>
          </div>
          </td>
         
          </tr>
        </table>
        <table style="border-bottom-left-radius: 5px;border-bottom-right-radius: 5px; justify-content: space-evenly; padding-bottom: 20px;padding-left:20px; background-color: #fff; width: 100%;">
          <tr>
          <td>
          <table>
            <tr>
              <span style="margin-bottom: 5px;">&nbsp;</span>
            </tr>
            <tr>
              <span
              style="padding-bottom: 5px; padding-top: 5px; display: flex; justify-content: start; align-items: center; margin-bottom: 5px; margin-right: 10px; font-size: 10px; font-weight: 600; color: rgb(29, 152, 152);">PD</span>
            </tr>
          </table>
          </td>
          <td>
          <div style="width: 50px;">
              <div style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;">Right Eye</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">
              ${data?.rightPd}
              </div>
          </div>
          </td>
          <td>
          <div style="width: 50px;">
              <div style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;">Left Eye</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">${
                data?.leftPd
              }</div>
          </div>
          </td>
          <td>
          <div style="width: 50px;margin-right:10px;">
            <div
              style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;"
            >
              Both Eyes
            </div>
            <div
              style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; background-color: rgb(241, 241, 241); display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;"
            >
            ${data?.bothPd}
            </div>
          </div>
          </td>
          </tr>
        </table>

        <table style="border-bottom-left-radius: 5px;border-bottom-right-radius: 5px; justify-content: space-evenly; padding-top: 5px; padding-bottom: 20px;padding-left:20px; background-color: #fff; width: 100%;">
          <tr>
          <td></td>
          <td>
          <div style="width: 50px;">
              <div style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;">Bifocal</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">
              ${
                data?.bifocal
                  ? `<div style=" display: inline-block;
                transform: rotate(50deg);
                height: 20px;
                width: 10px;
                border-bottom: 4px solid #78b13f;
                border-right: 4px solid #78b13f;"></div>`
                  : `<div></div>`
              }
              </div>
          </div>
          </td>
          <td>
          <div style="width: 75px;">
              <div style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;">Progressive</div>
              <div style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;">
              ${
                data?.progressive
                  ? `<div style=" display: inline-block;
                  transform: rotate(50deg);
                  height: 20px;
                  width: 10px;
                  border-bottom: 4px solid #78b13f;
                  border-right: 4px solid #78b13f;"></div>`
                  : `<div></div>`
              }
              </div>
          </div>
          </td>
          <td>
          <div style="width: 75px;margin-right:10px;">
            <div
              style="margin-bottom: 5px; font-size: 10px; font-weight: 600; display: flex; justify-content: center; align-items: center;"
            >
              Single Vision
            </div>
            <div
              style="padding-bottom: 5px; padding-top: 10px; border-radius:5px; display: flex; justify-content: center; align-items: center; margin-bottom: 5px;font-size: 10px; font-weight: 600;"
            >
            ${
              data?.single
                ? `<div style=" display: inline-block;
              transform: rotate(50deg);
              height: 20px;
              width: 10px;
              border-bottom: 4px solid #78b13f;
              border-right: 4px solid #78b13f;"></div>`
                : `<div></div>`
            }
            </div>
          </div>
          </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 20px;">
        <div style="background-color: rgb(29, 152, 152);border-top-left-radius: 5px;
        border-top-right-radius: 5px; width: 100%; padding-top: 10px;
        padding-bottom: 10px; margin-top: 10px; padding-left: 20px;">
          <span style="color: #fff; text-transform: capitalize; font-size: 13px; padding-left: 20px;"
            >Your Personal Optometrist</span
          >
        </div>
        <div style="padding-top: 20px; padding-bottom: 20px; background-color: #fff; width: 100%;border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;">
          <div style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <table style="width: 100%; padding-left: 50px;">
              <tr>
                <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Store Name</span></td>
                <td><span style="font-weight: 600;font-size: 10px; width: 50%;">Pampering Eyes</span></td>
              </tr>
            </table>
            </div>
          <div style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <table style="width: 100%; padding-left: 50px;">
              <tr>
                <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Store Address</span></td>
                <td><span style="font-weight: 600;font-size: 10px; width: 50%;">Near Karan Sweets , Kunjpura Road Karnal</span></td>
              </tr>
            </table>
          </div>
          <div style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 10px; padding-bottom: 5px;">
            <table style="display: flex; width: 100%; padding-left: 50px;">
              <tr>
                <td><span style="font-weight: 600; font-size: 10px; width: 50%;">Contact Details</span></td>
                <td><span style="font-weight: 600;font-size: 10px; width: 50%;">+91-9729512200</span></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
`

  return (
    <Document
      pageMode='fullScreen'
      author='Pampering Eyes'
      pageLayout='singlePage'
      title={`Test_report_${data?.name}.pdf`}
    >
      <Page size={'A4'} fixed>
        <Html>{html}</Html>
      </Page>
    </Document>
  )
}

export default PDFReport
