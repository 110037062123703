/* eslint-disable react/jsx-no-target-blank */

const Footer = () => {
  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()} &copy; Pampering Eyes
        </span>
      </div>
    </>
  )
}

export {Footer}
