import create from 'zustand'
import {persist, devtools} from 'zustand/middleware'

export interface IUser {
  uid: string
  email: string
  accessToken: string
}

interface AuthState {
  isLoggedIn: boolean
  userProfile: IUser
  setUserProfile: (t: IUser) => void
  setLoggedInStatus: (t: boolean) => void
}

export const useAuth = create<AuthState>()(
  persist(
    devtools((set, get) => ({
      isLoggedIn: false,
      userProfile: {} as IUser,
      setLoggedInStatus: (loggedInStatus: boolean) => {
        set(() => ({isLoggedIn: loggedInStatus}))
      },
      setUserProfile: (user: IUser) => {
        set(() => ({
          userProfile: {
            accessToken: user.accessToken,
            email: user.email,
            uid: user.uid,
          },
        }))
      },
    })),
    {
      name: 'opti-fb-app',
    }
  )
)
