import axios from 'axios'
const url = 'https://api.sendinblue.com/v3/smtp/email'

export const sendEmail = async (mailId: string, name: string, link: string) => {
  try {
    const params = {
      sender: {email: process.env.REACT_APP_FROM_EMAIL as string},
      to: [{email: mailId}],
      subject: `Prescription for ${name}`,
      templateId: 3,
      params: {
        name: name,
        link: link,
      },
    }
    const res = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_SENDIN_BLUE_API_KEY as string,
      },
    })
    return {success: true, error: null, res}
  } catch (error) {
    return {success: false, error: error, res: null}
  }
}
